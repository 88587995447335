import { Box, Button, ButtonBase, capitalize, List, Paper, Typography } from "@mui/material";
import { fetchPlatformData } from "../Platforms";
import Grid from "@mui/material/Grid2";
import { alpha, useTheme } from '@mui/material/styles';
import PageIntro from "../Components/PageIntro";
import { motion } from "framer-motion";



const ContentCards = ({ type }) => {
    let contents;
    let enquiryEmail;
    if (type === 'services') {
        let services = fetchPlatformData('services');
        contents = services.category;
        enquiryEmail = services.enquiryEmail;
    } else {
        let shop = fetchPlatformData('shop');
        contents = shop.category;
        enquiryEmail = shop.enquiryEmail;
    }

    const theme = useTheme();



    return <Box>
        <PageIntro>{capitalize(type)}</PageIntro>
        <Grid container sx={{ paddingLeft: 1 }} spacing={{ xs: 20, sm: 1 }} >
            {contents.map((category, i) => {
                return <>
                    <Grid size={{ xs: 12 }}>
                        {/* Category Name */}
                        <Typography variant="h5" paddingTop={4}>{category.name}</Typography>
                    </Grid>

                    {category.items.map(item => {
                        return <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.1 * i }}>
                                <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, borderRadius: 2, overflow: 'hidden' }}>

                                    <ButtonBase sx={{ width: "100%", textAlign: 'start', }}>
                                        <img style={{ display: 'flex', flexGrow: 1, maxHeight: 240, objectFit: 'cover', objectPosition: 'top', minWidth: 0 }} src={item.image} />
                                        <Box sx={{
                                            bottom: 0, display: 'flex', flexGrow: 1, flexDirection: 'row', position: 'absolute', bottom: 0,
                                            backgroundColor: alpha(theme.palette.background.paper, 0.9), backdropFilter: 'blur(10px)', padding: 1,
                                            justifyContent: 'space-between', alignItems: 'center', width: "100%",
                                        }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                <Typography variant="subtitle2" gutterBottom>{item.name}</Typography>
                                                <Typography variant="caption">{item.description}</Typography>
                                            </Box>

                                            {item.price && <Box sx={{ backgroundColor: "rgba(100, 100, 100, 0.80)", height: 80, padding: 2, margin: -1, marginLeft: 1, display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }} color="rgb(255, 255, 255)"> {item.price} </Typography>
                                            </Box>}

                                            {/* Enquire Now Button */}
                                            <a href={"mailto:"+enquiryEmail+"?subject="+capitalize(type)+" - "+category.name+"&body=Hi there, I would like to learn more about "+item.name}> <Button variant="contained">Enquire Now</Button> </a>
                                        </Box>
                                    </ButtonBase>
                                </Box>
                            </motion.div>
                        </Grid>
                    })}
                </>
            })}
        </Grid ></Box>
}

export default ContentCards;