import { Routes, Navigate, Route } from "react-router-dom"
import ContactUs from "./Pages/ContactUs"
import Gallery from "./Pages/Gallery"
import ContentCards from "./Pages/ContentCards"
import About from "./Pages/About"
import { motion } from "framer-motion"

const initial = { x: -40, opacity: 0 }
const animate = { x: 0, opacity: 1 }

const createPage = (Page, key, props = {}) => {
    key = key + new Date().valueOf();

    return <motion.div key={key + "_content"} animate={animate} initial={initial} transition={{ ease: 'easeOut', duration: 0.4 }} style={{ willChange: 'unset' }}>
        <Page />
    </motion.div>
}

const PageList = () => {
    return <Routes on>
        <Route path="*" element={<Navigate to="/about" replace />} />
        <Route exact path="/about" element={createPage(() => <About />, 'about')} />
        <Route exact path="/contact" element={createPage(() => <ContactUs />, 'ContactUs')} />
        <Route exact path="/gallery" element={createPage(() => <Gallery />, 'gallery')} />
        <Route exact path="/shop" element={createPage(() => <ContentCards type="shop" />, 'shop')} />
        <Route exact path="/services" element={createPage(() => <ContentCards type="services" />, 'services')} />
    </Routes>
}

export default PageList