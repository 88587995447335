import { Box, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

const HorizontalScroller = ({ quotes }) => {
    const [currentlyActive, setCurrentlyActive] = useState(0);

    useEffect(() => {
        const intervaller = setInterval(() => {
            setCurrentlyActive(value => {
                const next = value + 1
                if (next > quotes.length - 1) return 0
                return next
            })
        }, 5000)

        return () => clearInterval(intervaller)

    }, [])

    return <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', padding: 8 }}>
        <Typography variant="h4" sx={{ opacity: 0 }}>a</Typography>
        {quotes.map((quote, i) => {
            return <AnimatePresence>
                {currentlyActive === i && <motion.div initial={{ x: -250, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 250, opacity: 0 }} transition={{ duration: 1 }} style={{ position: 'absolute' }}>
                    <Typography variant="h4">
                        {quote}
                    </Typography>
                </motion.div>}
            </AnimatePresence>
        })}
    </Box>
}

export default HorizontalScroller;