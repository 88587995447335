import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PageIntro from "../Components/PageIntro";
import { alpha, useTheme } from '@mui/material/styles';
import HorizontalScroller from "../Components/HorizontalScroller";
import { useEffect } from "react";
import { fetchPlatformData } from "../Platforms";

const margins = { xs: 2, md: 12, lg: 24, xl: 36 }
const About = () => {

    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.only('xs'))

    const aboutData = fetchPlatformData('about');

    // useEffect(() => {
    //     console.log(xs)
    // }, [xs])

    const standardHeader = (orientation, text, desc, img) => {


        return <Grid size={{ xs: 12, sm: 6, md: 4 }} >
            <Paper sx={{ display: 'flex', flexDirection: 'column', padding: 1, borderRadius: 2, overflow: 'hidden' }} elevation={2}>

                <img src={img} />


                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingTop: 2 }}>


                    <Typography variant="h5" >
                        {text}
                    </Typography>



                    <Typography>{desc}</Typography>
                </Box>

            </Paper>
        </Grid>
    }
    return <Box>
        <HorizontalScroller quotes={aboutData.quotes} />
        <Grid container spacing={2} sx={{ padding: 2 }}>
            {aboutData.achievements.map((achievement, i) => {
                return standardHeader(i % 2 == 0 ? 1 : -1, achievement.title, achievement.description, achievement.image)
            })}
        </Grid>
    </Box>
}

export default About;