import './App.css';
import { Box, Container, CssBaseline, Typography, IconButton, useMediaQuery, createTheme, ThemeProvider, Button, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';
import React, { useEffect, useRef, useState } from 'react';


import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { alpha, useTheme } from '@mui/material/styles';
//import logo from './rampex.svg'
import { AnimatePresence, motion } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { green, red } from '@mui/material/colors';
import { fetchPlatformData } from './Platforms';

// const image = require('./rampex_main.jpg')
const image = fetchPlatformData('siteImages').main
const logo = fetchPlatformData('siteImages').logo

const vhOffset = '80vh'

const getTopLocation = (ref) => {
    return ref.current.getBoundingClientRect().top
}

const isAppBarDocked = (ref) => {
    return getTopLocation(ref) === 0
}

const StandardPresence = ({ children, delayExit, delayEnter }) => {
    return <motion.div
        initial={{ opacity: 0, display: 'none' }}
        animate={{ opacity: 1, transition: { delay: delayEnter, duration: 0.15 }, display: 'flex' }}
        exit={{ opacity: 0, transition: { delay: delayExit, duration: 0.15 } }}
        style={{ height: "100%", display: "flex", alignItems: 'center' }}
    >
        {children}
    </motion.div>
}

const barSpec = {
    xs: {
        max: 122,
        min: 72,
        paddingMax: 14,
        paddingMin: 4,
    },
    lg: {
        max: 200,
        min: 90,
        paddingMax: 20,
        paddingMin: 4,
    }
}


const navItems = [
    { label: "About", navigate: "/about" },
    { label: "Gallery", navigate: "/gallery" },
    { label: "Services", navigate: "/services" },
    { label: "Shop", navigate: "/shop" },
    { label: "Contact", navigate: "/contact" }
]

const PageHead = () => {
    const bg = useRef(null)
    const appBar = useRef(null);
    const [appBarDocked, setAppBarDocked] = useState()
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
    const [selectedNavigate, setSelectedNavigate] = useState('about')

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('md'))

    const navigateInternal = useNavigate()
    const navigate = (target) => {
        setSelectedNavigate(target)
        navigateInternal(target)
    };

    const selectedNavigateTreated = selectedNavigate.replace("/", "");

    const AppBarButton = ({ label, onClick }) => {
        const isSelected = label.toLowerCase() === selectedNavigateTreated
        return <Box>
            <Button onClick={onClick} style={{ height: "100%", color: 'white', borderRadius: 0, fontWeight: isSelected ? 700 : 300 }}>{label}</Button>
            {isSelected && <motion.div layoutId='nav_marker'>
                <Box sx={{ width: "100%", height: 2, backgroundColor: theme.palette.text.primary, marginTop: -2 / 8 }} />
            </motion.div>}
        </Box>
    }

    useEffect(() => {
        if (!bg.current) return;

        const barHeight = barSpec[xs ? "xs" : "lg"];
        appBar.current.style["margin-top"] = `${-1 * barHeight.max}px`

        const paralaxScroll = (ignoreDock) => {

            const scrollPosition = window.scrollY;
            const scrollPercent = Math.min(window.scrollY / (window.innerHeight * 0.8), 1)


            let isDocked;
            if (appBar.current) {
                isDocked = isAppBarDocked(appBar)
                setAppBarDocked(isDocked)
            }

            if (isDocked && ignoreDock !== true) return;

            bg.current.style.transform = `translateY(${Math.max(Math.round(scrollPosition * 0.1), 1)}px)`
            const blurTarget = `blur(${Math.round(scrollPosition) / 100}px)`
            if (bg.current.style.filter !== blurTarget) bg.current.style.filter = blurTarget

            appBar.current.style.height = `${barHeight.max - scrollPercent * (barHeight.max - barHeight.min)}px`
            appBar.current.style['padding-top'] = `${barHeight.paddingMax - scrollPercent * (barHeight.paddingMax - barHeight.paddingMin)}px`
            appBar.current.style['padding-bottom'] = `${barHeight.paddingMax - scrollPercent * (barHeight.paddingMax - barHeight.paddingMin)}px`

        }

        paralaxScroll(true)

        window.addEventListener('scroll', paralaxScroll)
        return () => window.removeEventListener('scroll', paralaxScroll)
    }, [xs])

    // console.log(appBarDocked)

    return <>
        <AutoScroll route={selectedNavigateTreated} />
        <Drawer
            variant="temporary"
            open={mobileDrawerVisible}
            onClose={() => setMobileDrawerVisible(false)}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            }}
            anchor='bottom'
        >
            <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton>
                        <LocationOnIcon sx={{ width: 42, height: 42, color: red[500] }} />
                    </IconButton>
                    <Box sx={{ width: 2 }} />
                    <IconButton>
                        <LocalPhoneIcon sx={{ width: 42, height: 42, color: green[500] }} />
                    </IconButton>
                </Box>
                <List>
                    {navItems.map((item) => (
                        <ListItem key={item.label} disablePadding onClick={() => {
                            navigate(item.navigate)
                            setMobileDrawerVisible(false)
                        }}>
                            <ListItemButton sx={{ backgroundColor: item.label.toLowerCase() === selectedNavigateTreated ? "primary.main" : "unset" }}>
                                <IconButton aria-label="settings">
                                    {item.icon}
                                </IconButton>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>

        <Container maxWidth={false} disableGutters ref={bg} style={{ backgroundImage: `url(${image})`, width: "100%", height: vhOffset, backgroundSize: 'cover', backgroundPosition: 'center', position: 'fixed' }} />

        <div style={{ height: vhOffset }} />

        <Box ref={appBar} sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.7), backdropFilter: 'blur(10px)', padding: 1,
            position: 'sticky', top: 0, display: "flex", alignItems: 'center', justifyContent: 'space-between', zIndex: 10, overflow: 'hidden'
        }}>

            <img src={logo} style={{ maxWidth: "50%", height: "100%", marginLeft: 16 }} />

            <Box id="bannerBar" sx={{ position: 'relative', display: 'flex', flexGrow: 1, justifyContent: 'flex-end', height: "100%", alignItems: 'center', marginRight: xs ? 0 : 2 }}>
                <AnimatePresence>
                    {true && <StandardPresence delayEnter={appBarDocked ? 0.15 : 0} delayExit={!appBarDocked ? 0 : 0}>
                        {xs && <><IconButton onClick={() => setMobileDrawerVisible(!mobileDrawerVisible)} >
                            <MenuIcon sx={{ width: 42, height: 42 }} />
                        </IconButton>
                        </>
                        }
                        {!xs && <>
                            {navItems.map(item => <AppBarButton label={item.label} onClick={() => navigate(item.navigate)} />)}
                        </>}
                    </StandardPresence>}
                </AnimatePresence>


            </Box>
        </Box >
    </>
}

const AutoScroll = ({ route }) => {
    const location = useLocation()

    useEffect(() => {
        const element = document.getElementById('bannerBar')
        const top = element.getBoundingClientRect().top
        if (top > 10) {
            setTimeout(() => element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }), route === 'gallery' ? 500 : 0);
        }
    }, [location])
}

export default PageHead