import { isPopulated } from "./Utilities";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const contentHost = "http://webblob.assingaround.com"
const API_ENDPOINT = "api.speedypik.com"
//const API_ENDPOINT = "localhost:5000"

// Return the index -> Update these later
function getPlatformIndex() {
    const domainName = window.location.hostname;
    if (domainName.toLowerCase().includes("gj")) return "gj";
    if (domainName.toLowerCase().includes("rvo")) return "rvo";
    if (domainName.toLowerCase().includes("rampex")) return "rampex";
    else return "rampex";
}

const defaultStyle = { width: 1050, height: 300 }
function fetchPlatformData(dataType) {
    const platforms = {
        gj: {
            name: "GJ Drivelines",
            siteImages: {
                logo: `${contentHost}/gjdrivelines.png`,
                main: `${contentHost}/gjmain.jpg`
            },
            staticPage: false,
            navMenu: [{
                label: 'Contact Us',
                toolbarType: "text",
                clickTarget: "contactus"
            }, {
                label: 'Gallery',
                toolbarType: "text",
                clickTarget: 'gallery'
            },
            {
                label: 'Services',
                toolbarType: "text",
                clickTarget: "services"
            },
            {
                label: 'Order',
                toolbarType: "text",
                clickTarget: "customorder"
            },
            {
                label: 'Store',
                toolbarType: "text",
                clickTarget: "store"
            }, {
                icon: (<ShoppingCartIcon />),
                label: "Cart",
                toolbarType: "icon",
                clickTarget: "store/cart",
                alwaysAppear: true
            }, {
                icon: (<PersonIcon />),
                label: "Account",
                toolbarType: "icon",
                clickTarget: "store/account",
                alwaysAppear: true
            }, {
                icon: (<AdminPanelSettingsIcon />),
                label: "Admin",
                toolbarType: "icon",
                clickTarget: "admin/main",
                alwaysAppear: true
            }],
            customerQuotes: ["Test google review multiline quote", "test google review 2"],
            contactUs: [{
                "branch_name": "Head Office - Keysborough",
                "branch_address": "2/189 Cheltenham Rd, Keysborough VIC 3173",
                "branch_map_link": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.3398199056037!2d145.18691947688455!3d-37.99253344427781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6139b26aa75a1%3A0xc3212155502b1e00!2sGJ%20Drivelines!5e0!3m2!1sen!2sau!4v1697139618689!5m2!1sen!2sau",
                "email": "gjdrivelines@live.com.au",
                "phone_number": "(03) 9706 3166",
                "trading_hours:": ["Monday to Thursday: 8am-5pm", "Friday: 8am-4pm", "Saturday & Sunday: Closed"],
                "social_links": [{ "name": "Facebook", "icon": "facebook_icon.png", "link": "https://www.facebook.com/tailshafts/" }, { "name": "Instagram", "icon": "instagram_icon.png", "link": "https://www.instagram.com/gjdrivelines/" }],
            },
            {
                "branch_name": "Somerton",
                "branch_address": "794 Cooper St, Somerton VIC 3062",
                "branch_map_link": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3159.168798002235!2d144.96730137687103!3d-37.64523542442682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64f167fa2584d%3A0x4a8991be8b3e1bee!2sGJ%20Drivelines!5e0!3m2!1sen!2sau!4v1697143311800!5m2!1sen!2sau",
                "email": "sales@gjdrivelines.com.au",
                "phone_number": "(03) 9305 7261",
                "trading_hours:": ["Monday to Thursday: 8am - 5pm", "Friday: 8am - 4pm", "Saturday & Sunday: Closed"],
                "social_links": [{ "name": "Facebook", "icon": "facebook_icon.png", "link": "https://www.facebook.com/gjdrivelinessomerton" }],
            }],
            services: {
                enquiryEmail: "tavrinn@hotmail.com",
                category: [{
                    name: "Performance",
                    items: [{
                        name: "Performance Tailshafts",
                        image: `${contentHost}/services/gj/performance_tailshafts.jpg`,
                        description: "(add extra description text here)"
                    },
                    {
                        name: "Performance Parts",
                        image: `${contentHost}/services/gj/tailshaft_reconditioning.jpg`,
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Reconditioning & Changeovers",
                    items: [{
                        name: "General Reconditioning",
                        image: `${contentHost}/services/gj/tailshaft_reconditioning.jpg`,
                        description: "(add extra description text here)"
                    },
                    {
                        name: "Changeovers",
                        image: `${contentHost}/services/gj/tailshaft_reconditioning.jpg`,
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Driveline Parts",
                    items: [{
                        name: "Manufacturing Services",
                        image: `${contentHost}/services/gj/tailshaft_reconditioning.jpg`,
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Heavy / Industrial",
                    items: [{
                        name: "Truck Shafts",
                        image: `${contentHost}/services/gj/tailshaft_reconditioning.jpg`,
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Transport",
                    items: [{
                        name: "Truck Shafts",
                        image: `${contentHost}/services/gj/tailshaft_reconditioning.jpg`,
                        description: "(add extra description text here)"
                    }]
                }]
            },
            shop: {
                enquiryEmail: "tavrinn@hotmail.com",
                category: [{
                    name: "Fuel Systems",
                    items: [{
                        name: "Surge tank No.1",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    },
                    {
                        name: "Surge tank No.2",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    }]
                },
                {
                    name: "Water Tanks",
                    items: [{
                        name: "Water tank No.1",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    },
                    {
                        name: "Water tank No.2",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    }]
                }]
            },


            orderForm: [
                {
                    name: 'Before you order',
                    paragraphs: [{
                        name: "",
                        type: "cards",
                        items: [
                            { name: "Tailshaft 101", type: "card", content: "How to measure for a custom Tailshaft", link: "/customtailshaftinfo" }
                        ]
                    }],
                    optional: false
                },
                {
                    name: 'Specs',
                    paragraphs: [{
                        name: "Chasis / Engine",
                        type: "fields",
                        items: [
                            { id: "chassisEngine_car", name: "Car / Chassis", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "chassisEngine_engine", name: "Engine", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "chassisEngine_induction", name: "Induction", type: "dropDown", items: ["Naturally Aspirated", "Supercharged", "Turbocharged", "Nitrous"], helperText: "must be selected", isError: (val) => isPopulated(val) }]
                    },
                    // Transmission Configuration
                    {
                        name: "Transmission",
                        type: "fields",
                        items: [
                            { id: "trans_name", name: "Make / Model / Brand", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_tbrake", name: "Transbrake", type: "dropDown", items: ["YES", "NO"], helperText: "must be selected", isError: (val) => isPopulated(val) },
                            { id: "trans_outputStyle", name: "Output Style", type: "dropDown", items: ["Slip Yoke", "3 Bolt Flange", "4 Bolt Flange", "6 Bolt Flange", "8 Bolt Flange", "Fixed Yoke"], trackState: true, helperText: "must be selected", isError: (val) => isPopulated(val) }]
                    },
                    {
                        name: "Transmission Slip Yoke",
                        type: "fields",
                        style: { display: "block" },
                        visibleIf: (state) => state.trans_outputStyle === "Slip Yoke",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/transmission-slip-yoke.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "Slip Yoke",
                        items: [
                            { id: "trans_slipYoke_n", name: "N - Spline Count", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_slipYoke_j", name: "J - Shaft Diameter", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_slipYoke_b", name: "B - Spline Extension", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "3 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "3 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/transmission-3-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "3 Bolt Flange",
                        items: [
                            { id: "trans_threeBoltFlange_m", name: "M", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_threeBoltFlange_f", name: "F", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_threeBoltFlange_b", name: "B - Spigot Diameter", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_threeBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "4 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "4 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/transmission-4-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "4 Bolt Flange",
                        items: [
                            { id: "trans_fourBoltFlange_j", name: "J - Measurement [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_fourBoltFlange_k", name: "K - Measurement [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_fourBoltFlange_h", name: "K - Bolt hole diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_fourBoltFlange_d", name: "D - Spigot Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_fourBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "6 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "6 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/transmission-6-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "6 Bolt Flange",
                        items: [
                            { id: "trans_sixBoltFlange_k", name: "K - PCD", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_sixBoltFlange_h", name: "H - Bolt Hole Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_sixBoltFlange_d", name: "D - Spigot Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_sixBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "8 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "8 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/transmission-8-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "8 Bolt Flange",
                        items: [
                            { id: "trans_eightBoltFlange_k", name: "K - PCD", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_eightBoltFlange_h", name: "H - Bolt Hole Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_eightBoltFlange_d", name: "D - Spigot Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_eightBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "Fixed Yoke",
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "Fixed Yoke",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/transmission-fixed-yoke.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.trans_outputStyle === "Fixed Yoke",
                        items: [
                            { id: "trans_fixedYoke_c", name: "C - Cap Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "trans_fixedYoke_e_g_selection", name: "Measurement Type", type: "dropDown", items: ["E", "G"], helperText: "must be selected", isError: (val) => isPopulated(val) },
                            { id: "trans_fixedYoke_e_g_measurement", name: "E or G - Length [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                        ]
                    },
                    // Differential Configuration
                    {
                        name: "Differential - Rear",
                        type: "fields",
                        items: [
                            { id: "diff_name", name: "Make/model/brand", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_input_style", name: "Input Style", type: "dropDown", items: ["Pinion Yoke", "3 Bolt Flange", "4 Bolt Flange", "6 Bolt Flange", "8 Bolt Flange"], trackState: true, helperText: "must be selected", isError: (val) => isPopulated(val) }]
                    },
                    {
                        name: "Pinion Yoke",
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "Pinion Yoke",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/differential-pinion-yoke.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "Pinion Yoke",
                        items: [
                            { id: "diff_pinionYoke_c", name: "C - Cap Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_pinionYoke_e_g_selection", name: "Measurement Type", type: "dropDown", items: ["E", "G"], helperText: "must be selected", isError: (val) => isPopulated(val) },
                            { id: "diff_pinionYoke_e_g_measurement", name: "E or G - Length [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                        ]
                    },
                    {
                        name: "3 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "3 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/differential-3-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "3 Bolt Flange",
                        items: [
                            { id: "diff_threeBoltFlange_m", name: "M", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_threeBoltFlange_f", name: "F", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_threeBoltFlange_b", name: "B - Spigot Diameter", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_threeBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "4 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "4 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/differential-4-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "4 Bolt Flange",
                        items: [
                            { id: "diff_fourBoltFlange_j", name: "J - Measurement [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_fourBoltFlange_k", name: "K - Measurement [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_fourBoltFlange_h", name: "K - Bolt hole diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_fourBoltFlange_d", name: "D - Spigot Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_fourBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "6 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "6 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/differential-6-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "6 Bolt Flange",
                        items: [
                            { id: "diff_sixBoltFlange_k", name: "K - PCD", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_sixBoltFlange_h", name: "H - Bolt Hole Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_sixBoltFlange_d", name: "D - Spigot Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_sixBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "8 Bolt Flange",
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "8 Bolt Flange",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/differential-8-bolt-flange.jpg", style: defaultStyle }
                        ]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.diff_input_style === "8 Bolt Flange",
                        items: [
                            { id: "diff_eightBoltFlange_k", name: "K - PCD", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_eightBoltFlange_h", name: "H - Bolt Hole Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_eightBoltFlange_d", name: "D - Spigot Diameter [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "diff_eightBoltFlange_spigotSex", name: "Spigot Sex", type: "dropDown", items: ["MALE", "FEMALE"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    // Tailshaft Configuration
                    {
                        name: "Tailshaft",
                        type: "fields",
                        items: [
                            { id: "tailshaft_style", name: "Style", type: "dropDown", items: ["1 Piece", "2 Piece", "3 Piece"], trackState: true, helperText: "must be selected", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_size_restrictions", name: "Size Restrictions", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_maxDia", name: "Max Diameter", type: "dropDown", items: ["<2.5\"", "2.5\"", "2.75\"", "3.0\"", "3.25\"", "3.50\"", "3.80\"", "4.00\"", "4.50\"", "5.00\"", "5\"+"], helperText: "must be selected", isError: (val) => isPopulated(val) }
                        ]
                    },
                    {
                        name: "Measurements - 1 piece",
                        type: "fields",
                        visibleIf: (state) => state.tailshaft_style === "1 Piece",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/tailshaft-1-piece.jpg", style: { width: 1400, height: 300 } }
                        ],
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.tailshaft_style === "1 Piece",
                        items: [
                            { id: "tailshaft_1_piece_a", name: "A - Overall Length [Transmission to Differential] [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) }
                        ],
                    },
                    {
                        name: "Measurements - 2 piece",
                        type: "fields",
                        visibleIf: (state) => state.tailshaft_style === "2 Piece",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/tailshaft-2-piece.jpg", style: { width: 1400, height: 300 } }]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.tailshaft_style === "2 Piece",
                        items: [
                            { id: "tailshaft_2_piece_a", name: "A - Overall Length [Transmission to Differential] [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_2_piece_x1", name: "X1 - Transmission to Center Bearing [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_2_piece_x2", name: "X2 - Center Bearing to Differential [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_2_piece_w1", name: "W1 - Center Bearing Bracket Bolt hole Spacing [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) }]
                    },
                    {
                        name: "Measurements - 3 piece",
                        type: "fields",
                        visibleIf: (state) => state.tailshaft_style === "3 Piece",
                        items: [
                            { name: "ImageName", type: "image", imgsrc: "/custom_order/gj/tailshaft-3-piece.jpg", style: { width: 1400, height: 300 } }]
                    },
                    {
                        type: "fields",
                        visibleIf: (state) => state.tailshaft_style === "3 Piece",
                        items: [
                            { id: "tailshaft_3_piece_a", name: "A - Overall Length [Transmission to Differential] [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_3_piece_x1", name: "X1 - Transmission to Center Bearing [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_3_piece_x2", name: "X2 - Center Bearing to Center Bearing [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_2_piece_x3", name: "X3 - Center Bearing to Differential [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_3_piece_w1", name: "W1 - Center Bearing Bracket Bolt hole Spacing [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "tailshaft_3_piece_w2", name: "W2 - Center Bearing Bracket Bolt hole Spacing [mm]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) }]
                    },
                    {
                        name: "Limits",
                        type: "fields",
                        items: [
                            { id: "limits_targetHP", name: "Target HP", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "limits_targetTorque", name: "Target Torque [NM]", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "limits_maxRPM", name: "Max RPM", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) }]
                    },
                    {
                        name: "Other",
                        type: "fields",
                        items: [
                            { id: "other_usage", name: "What is the car being used for? (Street, Race, etc.)", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                            { id: "other_material_preference", name: "Material preference", type: "dropDown", items: ["Steel", "CrMo", "Aluminimum", "Carbon Fiber", "Best Recommendation"], helperText: "must be selected", isError: (val) => isPopulated(val) },
                            { id: "other_who_will_install", name: "Who will install?", type: "dropDown", items: ["Myself", "A Workshop"], helperText: "must be selected", isError: (val) => isPopulated(val) }]
                    },
                    ],
                    optional: false
                }
            ],
            about: {
                quotes: ['GJ has been a valuable partner', 'GJ ramped us', 'Something else...'],
                achievements: [{
                    title: "Unrivalled Excellence",
                    description: "GJ is a leading manufacturer of performance tailshafts",
                    image: `${contentHost}/services/gj/performance_tailshafts.jpg`
                },
                {
                    title: "Our Clients",
                    description: "We work with a wide array of clients and build all sorts of tailshafts",
                    image:  `${contentHost}/services/gj/tailshaft_reconditioning.jpg`
                },
                {
                    title: "Spare Parts",
                    description: "We have a wide range of center bearings in stock",
                    image: `${contentHost}/services/gj/centre_bearings.jpg`
                }]
            },
            imageGallery: [{ imgPath: `${contentHost}/image_gallery/gj_drivelines/GJ4X4.jpg`, imgDescription: "GJ 4x4 Jets" },
            { imgPath: `${contentHost}/image_gallery/gj_drivelines/GJ-Shirt-Art.jpg`, imgDescription: "GJ Shirts" },
            { imgPath: `${contentHost}/image_gallery/gj_drivelines/IMG_5501-2.jpg`, imgDescription: "GJ Misc" },
            { imgPath: `${contentHost}/image_gallery/gj_drivelines/IMG_7277-1.jpg`, imgDescription: "GJ Misc" },
            ],
            mainHeader: {
                title: "Driveline and tailshaft specialist",
                googleReviewsLink: "https://www.google.com/search?q=gj+drivelines&oq=GJ+&gs_lcrp=EgZjaHJvbWUqCQgAECMYJxiKBTIJCAAQIxgnGIoFMgYIARBFGDkyBggCECMYJzIGCAMQRRg8MgYIBBBFGDwyBggFEEUYPDIGCAYQRRg8MgYIBxBFGDzSAQgxMTEyajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#",
                storeLocations: [{
                    name: "Keysborough",
                    socials: [{
                        name: "Phone",
                        link: "tel:0397063166",
                    },
                    {
                        name: "Facebook",
                        link: "https://www.facebook.com/tailshafts/",
                    },
                    {
                        name: "Instagram",
                        link: "https://www.instagram.com/gjdrivelines/",
                    }]
                },
                {
                    name: "Somerton",
                    socials: [{
                        name: "Phone",
                        link: "tel:0393057261"
                    }, {
                        name: "Facebook",
                        link: "https://www.facebook.com/gjdrivelinessomerton",
                    }]
                }
                ]
            },
            theme: {
                primary: {
                    main: "rgb(241, 120, 3)",
                    background: "rgba(134, 42, 61, 0.4)",
                    backgroundLight: "rgba(134, 42, 61, 0.2)",
                    backgroundDark: "rgba(134, 42, 61, 0.8)"
                  },
                  secondary: {
                    main: "rgb(241, 120, 3)",
                    backgroundDark: "rgba(29, 48, 80, 0.8)",
                    background: "rgba(29, 48, 80, 0.2)",
                    backgroundLight: "rgba(29, 48, 80, 0.1)",
                  }
            }
        },
        rvo: {
            name: "RVO Automatics",
            siteImages: {
                logo: `${contentHost}/rvo_logo.png`,
                main: `${contentHost}/rvo_main.jpg`
            },
            staticPage: true,
            navMenu: [{
                label: 'Contact Us',
                toolbarType: "text",
                clickTarget: "contactus"
            }, {
                label: 'Gallery',
                toolbarType: "text",
                clickTarget: 'gallery'
            },
            {
                label: 'Services',
                toolbarType: "text",
                clickTarget: "services"
            },
            {
                label: 'Shop',
                toolbarType: "text",
                clickTarget: "shop"
            }],
            contactUs: [{
                "branch_name": "Head Office - Dandenong South",
                "branch_address": "4 Egan Rd, Dandenong South VIC 3175",
                "branch_map_link": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.2152509702332!2d145.19133467688462!3d-37.99543944444463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6139066b40d9f%3A0x31282981dbe500ed!2sRVO%20Performance%20%26%20Automatics!5e0!3m2!1sen!2sau!4v1697160273249!5m2!1sen!2sau",
                "email": "rvoautomatics@yahoo.com.au",
                "phone_number": "(03) 9794 5004",
                "trading_hours:": ["Monday to Friday: 8:30am - 5:30pm", "Saturday & Sunday: Closed"],
                "social_links": [{ "name": "Facebook", "icon": "facebook_icon.png", "link": "https://www.facebook.com/RVOAUTOMATICS/" }, { "name": "TikTok", "icon": "tiktok_icon.png", "link": "https://www.tiktok.com/@rvoautomatics" }],
            }],
            services: {
                enquiryEmail: "tavrinn@hotmail.com",
                category: [{
                    name: "Performance Transmissions",
                    items: [{
                        name: "Performance Upgrades",
                        image: `${contentHost}/image_gallery/rvo/1.jpg`,
                        description: "(add extra description text here)"
                    },
                    {
                        name: "Performance Rebuilds",
                        image: `${contentHost}/image_gallery/rvo/2.jpg`,
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Transmission Rebuilds",
                    items: [{
                        name: "General Rebuilds",
                        image: `${contentHost}/image_gallery/rvo/19.jpg`,
                        description: "(add extra description text here)"
                    },
                    {
                        name: "Performance Rebuilds",
                        image: `${contentHost}/image_gallery/rvo/7.jpg`,
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Transmission Service",
                    items: [{
                        name: "In Car Tranny Services",
                        image: `${contentHost}/image_gallery/rvo/12.jpg`,
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Parts and Service",
                    items: [{
                        name: "In Car Tranny Services",
                        image: `${contentHost}/image_gallery/rvo/17.jpg`,
                        description: "(add extra description text here)"
                    }]
                }]
            },
            shop: {
                enquiryEmail: "tavrinn@hotmail.com",
                category: [{
                    name: "Holden - 4 Speed",
                    items: [{
                        name: "4L60E - Stage 1",
                        image: `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$1000",
                        description: "4L60E Stage 1 description text"
                    },
                    {
                        name: "4L60E - Stage 2",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$2500",
                        description: "4L60E Stage 2 description text"
                    },
                    {
                        name: "4L60E - Stage 3",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$3500",
                        description: "4L60E Stage 3 description text"
                    },
                    {
                        name: "4L60E - Stage 4",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$6000",
                        description: "4L60E Stage 4 description text"
                    }]
                },
                {
                    name: "Holden - 5 Speed",
                    items: [{
                        name: "5L40E - Stage 1",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$1000",
                        description: "5L40E Stage 1 description text"
                    },
                    {
                        name: "5L40E - Stage 2",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$2500",
                        description: "5L40E Stage 2 description text"
                    },
                    {
                        name: "5L40E - Stage 2",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$2500",
                        description: "5L40E Stage 2 description text"
                    },
                    {
                        name: "5L40E - Stage 2",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$2500",
                        description: "5L40E Stage 2 description text"
                    }]
                },
                {
                    name: "Holden - 6 Speed",
                    items: [{
                        name: "6L80E - Stage 1",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$1000",
                        description: "6L80E Stage 1 description text"
                    },
                    {
                        name: "6L80E - Stage 1",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$1000",
                        description: "6L80E Stage 1 description text"
                    },
                    {
                        name: "6L80E - Stage 1",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$1000",
                        description: "6L80E Stage 1 description text"
                    },
                    {
                        name: "6L80E - Stage 1",
                        image:  `${contentHost}/shop/rvo/4l60e.jpg`,
                        price: "$1000",
                        description: "6L80E Stage 1 description text"
                    }]
                }]
            },
            orderForm: [
                {
                    name: 'Before your order',
                    paragraphs: [{
                        name: "",
                        type: "cards",
                        items: [
                            { name: "Transmission 101", type: "card", content: "101 brief description on how to order your RVO transmission" }
                        ]
                    }],
                    optional: false
                },
                {
                    name: 'Your',
                    paragraphs: [{
                        name: "Name",
                        type: "fields",
                        items: [
                            { name: "First Name", type: "textInput" },
                            { name: "Last Name", type: "textInput" },
                            { name: "Business Name", type: "textInput" },
                            { name: "Phone Number", type: "textInput" },
                            { name: "Email", type: "textInput" }
                        ]
                    }, {
                        name: "Address",
                        type: "fields",
                        items: [
                            { name: "Address", type: "textInput" },
                            { name: "Suburb", type: "textInput" },
                            { name: "Postcode", type: "textInput" },
                            { name: "State", type: "dropDown", items: ["New South Wales", "Victoria", "Queensland", "Western Australia", "South Australia", "Australian Capital Territory", "Tasmania", "Northern Territory"] }]
                    }],
                    optional: false
                },
                {
                    name: 'Your Custom Order',
                    paragraphs: [{
                        name: "Chasis / Engine",
                        type: "fields",
                        items: [{ name: "Car / Chassis", type: "textInput" },
                        { name: "Engine", type: "textInput" },
                        { name: "Engine Induction", type: "dropDown", items: ["Naturally Aspirated", "Supercharged", "Turbocharged", "Twin Charged", "Nitrous"] },
                        { name: "Fuel Type", type: "dropDown", items: ["Gas", "Diesel", "Hybrid"] }]
                    },
                    {
                        name: "Maximum Limits",
                        type: "fields",
                        items: [{ name: "How much HP are you planning to make", type: "textInput" },
                        { name: "How much Torque are you planning to make", type: "textInput" },
                        { name: "What is the MAX Engine RPM", type: "textInput" }]
                    },
                    {
                        name: "Other",
                        type: "fields",
                        items: [{ name: "What is the car being used for? (Street, Race, etc.)", type: "textInput" },
                        { name: "Who Will be Installing?", type: "dropDown", items: ["Myself", "A Workshop"] }]
                    },
                    ],
                    optional: false
                }
            ],
            about: {
                quotes: ['RVO is number 1', 'RVO specialises in autos', 'Something else...'],
                achievements: [{
                    title: "Unrivalled Excellence",
                    description: "RVO is a leading manufacturer of",
                    image: `${contentHost}/image_gallery/rvo/15.jpg`,
                },
                {
                    title: "Our Clients",
                    description: "We work with a wide array of clients",
                    image: `${contentHost}/image_gallery/rvo/22.jpg`,
                },
                {
                    title: "Our Projects",
                    description: "We've worked on some impressive projects",
                    image: `${contentHost}/image_gallery/rvo/8.jpg`,
                }]
            },

            imageGallery: [ { imgPath: `${contentHost}/image_gallery/rvo/1.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/2.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/3.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/4.jpg`, imgDescription: "Fresh 4L60E" },
                            { imgPath: `${contentHost}/image_gallery/rvo/5.jpg`, imgDescription: "Fresh 4L60E" },
                            { imgPath: `${contentHost}/image_gallery/rvo/6.jpg`, imgDescription: "RVO Headquarters" },
                            { imgPath: `${contentHost}/image_gallery/rvo/7.jpg`, imgDescription: "KChing 427 Stroker" },
                            { imgPath: `${contentHost}/image_gallery/rvo/8.jpg`, imgDescription: "KChing Motor" },
                            { imgPath: `${contentHost}/image_gallery/rvo/9.jpg`, imgDescription: "Build room" },
                            { imgPath: `${contentHost}/image_gallery/rvo/10.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/11.jpg`, imgDescription: "KChing Motor" },
                            { imgPath: `${contentHost}/image_gallery/rvo/12.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/13.jpg`, imgDescription: "ZF King" },
                            { imgPath: `${contentHost}/image_gallery/rvo/15.jpg`, imgDescription: "KChing Jet 747" },
                            { imgPath: `${contentHost}/image_gallery/rvo/16.jpg`, imgDescription: "Trackhawk delivery jet" },
                            { imgPath: `${contentHost}/image_gallery/rvo/17.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/18.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/19.jpg`, imgDescription: "Jets" },
                            { imgPath: `${contentHost}/image_gallery/rvo/20.jpg`, imgDescription: "Dave tearing shit up" },
                            { imgPath: `${contentHost}/image_gallery/rvo/21.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/22.jpg`, imgDescription: "KChing on the brake" },
                            { imgPath: `${contentHost}/image_gallery/rvo/23.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/24.jpg`, imgDescription: "KChing" },
                            { imgPath: `${contentHost}/image_gallery/rvo/25.jpg`, imgDescription: "RVO King" },
                            { imgPath: `${contentHost}/image_gallery/rvo/26.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/27.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/28.jpg`, imgDescription: "RVO David working hard" },
                            { imgPath: `${contentHost}/image_gallery/rvo/29.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/30.jpg`, imgDescription: "RVO Business Card" },
                            { imgPath: `${contentHost}/image_gallery/rvo/31.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/32.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/33.jpg`, imgDescription: "KCHING" },
                            { imgPath: `${contentHost}/image_gallery/rvo/34.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/35.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/36.jpg`, imgDescription: "RVO HQ" },
                            { imgPath: `${contentHost}/image_gallery/rvo/37.jpg`, imgDescription: "RVO LED SIGN" },
                            { imgPath: `${contentHost}/image_gallery/rvo/38.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/39.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/40.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/41.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/42.jpg`, imgDescription: "Fresh Transmission" },
                            { imgPath: `${contentHost}/image_gallery/rvo/43.jpg`, imgDescription: "Zilla Motor" },
                            { imgPath: `${contentHost}/image_gallery/rvo/44.jpg`, imgDescription: "KChing Spotted" },
            ],
            mainHeader: {
                title: "Performance Modifications & Transmission Specialist",
                googleReviewsLink: "https://www.google.com/search?q=rvo+automatics&oq=rvo+automatics&gs_lcrp=EgZjaHJvbWUqCwgAEEUYJxg7GIoFMgsIABBFGCcYOxiKBTIHCAEQABiABDIICAIQRRgnGDsyBwgDEAAYgAQyBwgEEAAYgAQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGDzSAQgxODY5ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#",
                storeLocations: [{
                    name: "Dandenong",
                    socials: [{
                        name: "Phone",
                        link: "tel:0397945004",
                    },
                    {
                        name: "Facebook",
                        link: "https://www.facebook.com/RVOAUTOMATICS/",
                    },
                    {
                        name: "TikTok",
                        link: "https://www.tiktok.com/@rvoautomatics",
                    }]
                }]
            },
            theme: {
                primary: {
                    main: "rgb(25, 124, 43)",
                    background: "rgba(134, 42, 61, 0.4)",
                    backgroundLight: "rgba(134, 42, 61, 0.2)",
                    backgroundDark: "rgba(134, 42, 61, 0.8)"
                  },
                  secondary: {
                    main: "rgb(125, 124, 43)",
                    backgroundDark: "rgba(29, 48, 80, 0.8)",
                    background: "rgba(29, 48, 80, 0.2)",
                    backgroundLight: "rgba(29, 48, 80, 0.1)",
                  }
            }
        },
        rampex: {
            name: "Rampex",
            siteImages: {
                logo: `${contentHost}/rampex_logo.png`,
                main: `${contentHost}/rampex_main.jpg`,
            },
            staticPage: true,
            navMenu: [{
                label: 'Contact Us',
                toolbarType: "text",
                clickTarget: "contactus"
            }, {
                label: 'Gallery',
                toolbarType: "text",
                clickTarget: 'gallery'
            },
            {
                label: 'Services',
                toolbarType: "text",
                clickTarget: "services"
            },
            {
                label: 'Shop',
                toolbarType: "text",
                clickTarget: "shop"
            }],
            contactUs: [{
                "branch_name": "Rampex Pty Ltd",
                "branch_address": "Units 1 and 2, 11 Kempson Court, Keysborough VIC 3173",
                "branch_map_link": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6288.182957483356!2d145.18202321244377!3d-37.99832667181292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6136bb325654f%3A0x700b460ddff8ae43!2sRampex%20Engineering!5e0!3m2!1sen!2sau!4v1732620934354!5m2!1sen!2sau",
                "email": "mike.henry@menray.com",
                "phone_number": "0416 741 222",
                "trading_hours:": ["Monday to Friday: 8:30am - 5:30pm", "Saturday & Sunday: Closed"],
                "social_links": [{ "name": "Facebook", "icon": "facebook_icon.png", "link": "https://www.facebook.com/menrayrace/" }, { "name": "Instagram", "icon": "instagram_icon.png", "link": "https://www.instagram.com/menrayrace/" }],
            }],
            services: {
                enquiryEmail: "tavrinn@hotmail.com",
                category: [{
                    name: "Race",
                    items: [{
                        name: "Race Engineering",
                        image: contentHost + "/services/rampex/racing.jpg",
                        description: "(add extra description text here)"
                    },
                    {
                        name: "Race Support",
                        image: contentHost + "/services/rampex/engineering.jpg",
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Engineering",
                    items: [{
                        name: "General Engineering",
                        image: contentHost + "/services/rampex/manufacturing.jpg",
                        description: "(add extra description text here)"
                    },
                    {
                        name: "Engineering Design",
                        image: contentHost + "/services/rampex/manufacturing.jpg",
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Fabrication",
                    items: [{
                        name: "Fabrication Services",
                        image: contentHost + "/services/rampex/manufacturing.jpg",
                        description: "(add extra description text here)"
                    }]
                },
                {
                    name: "Manufacturing",
                    items: [{
                        name: "Manufacturing Services",
                        image: contentHost + "/services/rampex/manufacturing.jpg",
                        description: "(add extra description text here)"
                    }]
                }]
            },
            shop: {
                enquiryEmail: "tavrinn@hotmail.com",
                category: [{
                    name: "Fuel Systems",
                    items: [{
                        name: "Surge tank No.1",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    },
                    {
                        name: "Surge tank No.2",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    }]
                },
                {
                    name: "Water Tanks",
                    items: [{
                        name: "Water tank No.1",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    },
                    {
                        name: "Water tank No.2",
                        image: contentHost + "/shop/rampex/fuel_tank.jpg",
                        price: "$2500",
                        description: "Fuel tank - Custom designed to suit your needs. Prices start at $2500. Contact us to get started"
                    }]
                }]
            },
            about: {
                quotes: ['Rampex has been a valuable partner', 'Rampex ramped us', 'Something else'],
                achievements: [{
                    title: "Unrivalled Excellence",
                    description: "Rampex is a leading manufacturer of",
                    image: contentHost + "/services/rampex/racing.jpg"
                },
                {
                    title: "Our Clients",
                    description: "We work with a wide array of clients",
                    image: contentHost + "/services/rampex/racing.jpg"
                },
                {
                    title: "Our Projects",
                    description: "We've worked on some impressive projects",
                    image: contentHost + "/services/rampex/racing.jpg"
                }]
            },
            imageGallery: [ { imgPath: `${contentHost}/image_gallery/rampex/1.jpg`, imgDescription: "This is a test description" },
                            { imgPath: `${contentHost}/image_gallery/rampex/2.jpg`, imgDescription: "This is a test description" },
                            { imgPath: `${contentHost}/image_gallery/rampex/3.jpg`, imgDescription: "This is a test description" },
                            { imgPath: `${contentHost}/image_gallery/rampex/4.jpg`, imgDescription: "This is a test descrption" },
                            { imgPath: `${contentHost}/image_gallery/rampex/5.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/6.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/7.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/8.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/9.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/10.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/11.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/12.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/13.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/14.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/15.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/16.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/17.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/18.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/19.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/20.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/21.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/22.jpg`, imgDescription: "" },
                            { imgPath: `${contentHost}/image_gallery/rampex/23.jpg`, imgDescription: "" }
            ],
            mainHeader: {
                title: "Rampex Pty LTD - Engineering Specialists",
                googleReviewsLink: "https://www.google.com/maps/place/Menray+Race+Engineering+Pty+Ltd/@-37.912114,145.4160041,13z/data=!4m6!3m5!1s0x6ad623a07551e9cb:0x3c13d228602aef18!8m2!3d-37.9097777!4d145.358637!16s%2Fg%2F11lm49sk9g?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D",
                storeLocations: [{
                    name: "Keysborough",
                    socials: [{
                        name: "Phone",
                        link: "tel:0416741222",
                    },
                    {
                        name: "Facebook",
                        link: "https://www.facebook.com/menrayrace/",
                    },
                    {
                        name: "Instagram",
                        link: "https://www.instagram.com/menrayrace/",
                    }]
                }]
            },
            theme: {
                primary: {
                    main: "rgb(48, 46, 43)",
                    background: "rgba(134, 42, 61, 0.4)",
                    backgroundLight: "rgba(134, 42, 61, 0.2)",
                    backgroundDark: "rgba(134, 42, 61, 0.8)"
                  },
                  secondary: {
                    main: "rgb(48, 46, 43)",
                    backgroundDark: "rgba(29, 48, 80, 0.8)",
                    background: "rgba(29, 48, 80, 0.2)",
                    backgroundLight: "rgba(29, 48, 80, 0.1)",
                  }
            }
        }
    }

    return platforms[getPlatformIndex()][dataType]
}

export { API_ENDPOINT, fetchPlatformData };