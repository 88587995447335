import './App.css';
import { Box, Container, CssBaseline, Typography, IconButton, useMediaQuery, createTheme, ThemeProvider, Button, Paper } from '@mui/material';
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';
import React, { useEffect, useRef, useState } from 'react';
import PageHead from './PageHead';
import PageList from './PageList';
import { BrowserRouter } from 'react-router-dom';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { fetchPlatformData } from './Platforms';

function App() {
  document.title = fetchPlatformData("name");
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const platformTheme = fetchPlatformData('theme');
  const [currentTheme, setCurrentTheme] = useState({ colorMode: prefersDarkMode ? 'dark' : 'light', primary : platformTheme.primary, secondary: platformTheme.secondary });

  const muiTheme = createTheme({
    palette: {
      mode: currentTheme.colorMode,
      primary: {
        main: currentTheme.primary.main,
      },
      secondary: {
        main: currentTheme.secondary.main,
      },
      raw: currentTheme,
      funct: {
        modeAdjust: (int) => currentTheme.colorMode === 'dark' ? 900 - Math.max(int, 50) : int
      }
    }
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <BrowserRouter>
        <ThemeProvider theme={muiTheme}>
          <Container disableGutters maxWidth={false}>
            <Paper elevation={0}>
              <PageHead />
              <Box style={{ minHeight: "100vh", position: 'relative', background: muiTheme.palette.background.paper }}>
                <PageList />
              </Box>
            </Paper>
          </Container>
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
