import { Box, Container, Paper } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { motion } from 'framer-motion'

const Modal = ({ children, sx, bypassAnimatedContainer, onExit }) => {
    const theme = useTheme();

    return <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} style={{ zIndex: 999, position: "fixed", left: 0, top: 0, width: "100%", height: "100%", }}>
        <Box sx={{ backgroundColor: alpha(theme.palette.background.paper, 0.95), justifyContent: 'center', alignItems: "center", display: "flex", height: "100%" }} onClick={onExit}>
            <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "center" }}>
                {!bypassAnimatedContainer && <motion.div initial={{ y: 50 }} exit={{ y: -50 }} animate={{ y: 0 }} transition={{ duration: 0.2 }} style={{ width: "calc(100% - 32px)" }}>
                    <Box sx={{ display: "flex", flexGrow: 1, justifyContent: 'center' }}>

                        <Paper elevation={3} sx={{
                            p: 2,
                            ...sx
                        }}>
                            {children}
                        </Paper>
                    </Box>
                </motion.div>}
                {bypassAnimatedContainer && <>{children}</>}
            </Container>
        </Box>
    </motion.div>
}

export default Modal;