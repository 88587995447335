import { useEffect, useRef, useState } from 'react';
import PageIntro from '../Components/PageIntro';
import { fetchPlatformData } from '../Platforms';
import { Box, ButtonBase, Paper, Typography, useMediaQuery } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import { motion } from 'framer-motion'
import Modal from '../Components/Modal'
import ArrowBackIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowForwardIcon from '@mui/icons-material/KeyboardArrowRight';
const Gallery = ({ name }) => {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('md'))

    const images = fetchPlatformData('imageGallery')
    const [selectedImage, setSelectedImage] = useState(null);
    const selectedImageRef = useRef(null);
    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        const escapeFunction = (event) => {
            if (event.key === "Escape" && selectedImage) {
                setSelectedImage(null)
            }
        }

        const showTask = setTimeout(() => setShouldShow(true))
        document.addEventListener('keyup', escapeFunction, false)

        return () => {
            document.removeEventListener('keyup', escapeFunction, false)
            clearTimeout(showTask)
        }
    }, [])
    // useEffect(() => {
    //     if (selectedImageRef.current) {
    //         const test = selectedImageRef.current.getBoundingClientRect()
    //         console.log(test)
    //     }
    // }, [selectedImageRef, selectedImage])

    const clickArrow = (e, direction) => {
        const current = selectedImage.i + direction
        setSelectedImage({ image: images[current], i: current })
        e.stopPropagation()
    }
    let arrowSize = xs ? 32 : 56
    arrowSize = { width: arrowSize, height: arrowSize }

    return (
        <>
            <PageIntro>Check out some of our work</PageIntro>

            <Masonry columns={{ xs: 2, sm: 2, md: 3, lg: 4, xl: 5 }} sx={{ display: shouldShow ? 'flex' : 'unset' }}>
                {images.map((image, i) => {
                    return <ButtonBase style={{
                        display: 'inline'
                    }} onClick={() => setSelectedImage({ image, i })}>
                        <motion.div layoutId={image.imgPath} >
                            <img style={{ width: "100%", borderRadius: 8 }} src={image.imgPath} />
                        </motion.div>
                    </ButtonBase >
                })}
            </Masonry >

            {selectedImage && <Modal bypassAnimatedContainer={true} onExit={() => setSelectedImage(null)} sx={{}}>

                <motion.div style={{ position: 'relative' }} layoutId={selectedImage.image.imgPath}>
                    {selectedImage.image.imgDescription && <Box sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%)', bottom: 0, backgroundColor: alpha(theme.palette.background.paper, 0.8), backdropFilter: 'blur(2px)', padding: 2, borderRadius: 2, marginTop: 1 }}>
                        <Typography variant='subtitle'>
                            {selectedImage.image.imgDescription}
                        </Typography>
                    </Box>}

                    <img ref={selectedImageRef} style={{ width: '95vw', maxHeight: "90vh", objectFit: 'contain' }} src={selectedImage.image.imgPath} />

                </motion.div>

                {selectedImage.i > 0 && <ButtonBase sx={{ position: 'fixed', top: "50%", left: 0, padding: 1, transform: 'translate(0%,-50%)', backgroundColor: alpha(theme.palette.background.paper, 0.8), borderRadius: 1 }} onClick={e => clickArrow(e, -1)}>
                    <ArrowBackIcon sx={arrowSize} />
                </ButtonBase>}

                {selectedImage.i < images.length - 1 && <ButtonBase sx={{ position: 'fixed', top: "50%", right: 0, padding: 1, transform: 'translate(0%,-50%)', backgroundColor: alpha(theme.palette.background.paper, 0.8), borderRadius: 1 }} onClick={e => clickArrow(e, 1)}>
                    <ArrowForwardIcon sx={arrowSize} />
                </ButtonBase>}
            </Modal>}
        </>
    );
};

export default Gallery;


